import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/buddy/openbook-github-io/workspaces/packages/gatsby-theme-andybrace/src/components/Layout/index.js";
import { ProjectsContainer } from "../components";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h1" components={components}>{`Projects`}</MDXTag>
      <ProjectsContainer number={0} />
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      